<template>
<b-container>
  <b-row>
    <b-col sm="auto" md="10" lg="8" xl="6">
      <h1>Användare</h1>
      <div v-if="loading">
        <b-spinner label="Spinning"></b-spinner>
      </div>
        <b-table v-else hover responsive="xl" :items="users" :fields="fields" sortBy="email">
          <template #cell(id)="data">
            <router-link :to="{ name: 'profile', query: { uid: data.item.id}}">{{ data.item.id }}</router-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { db } from '@/firebaseConfig.js'
  import { collection, orderBy, onSnapshot, query } from 'firebase/firestore'

  export default {
    mounted() {
      const q = query(collection(db, 'users'), orderBy('email', 'asc'))
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        const result = []
        querySnapshot.forEach((doc) => {
          result.push({ id: doc.id, ...doc.data() })
        })
        this.users = result
        this.loading = false
      })
    },
    unmounted() {
      this.unsubscribe()
    },
    data() {
      return {
        loading: true,
        users: [],
        unsubscribe: null,
        fields: [{ key: 'email', label: 'Epost', sortable: true },
                 { key: 'instructor', label: 'Instruktör', sortable: true, formatter: value => { return value ? '✓' : ''} },
                 { key: 'admin', sortable: true, formatter: value => { return value ? '✓' : ''} },
                 { key: 'createdAt', label: 'Skapad', sortable: true, sortByFormatted: true, formatter: value => { return value ? new Date(value.seconds*1000).toISOString().substring(0,10) : '' } },
                 { key: 'id', label: 'Uid' }]
      }
    }
  }

</script>
